import Img from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"
import React from "react"
import style from "../styles/blessimg.module.scss"

const BLessImg = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                sizes(maxWidth: 300) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename)
      })
      if (!image) {
        return null
      }

      const imageSizes = image.node.childImageSharp.sizes
      return (
        <div
          class={style.box}
        >
          <figure>
            <Img alt={props.alt} sizes={imageSizes} />
            <figcaption>{props.alt}</figcaption>
          </figure>
        </div>
      )
    }}
  />
)

export default BLessImg
