import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../../components/seo-rando"
import Layout from "../../components/layout"
import SnapImg from "../../components/blessimg"
import "../../styles/travellog.scss"
const TravelLog = ({ data }) => {
return (
    <Layout>
    <SEO
      title="Trekathlon"
      descriptiom="Descriptions de randonnées et balades"
      keywords={[
        `Trekking`,
        `Travel`,
        `Tips`,
        `Budget`,
        `Photography`,
        `Travel`,
      ]}
      meta={[{ name: "robots", content: "noindex" }]}
    />
          <section >
              <div class="travellogsection" >
              <h1>Travel Log</h1>
    <Link to="https://trello.com/b/T9phZ0V7/voyage">Trello Travel Log</Link> <br></br>

{data.allTrelloCard.totalCount } cartes sont actuellement disponibles.
<div class="collection">
{data.allTrelloCard.edges.map(({node}) => (
    <div class="card">
        <div class="title"><h1>{node.list_name} - {node.name}</h1></div>
        <div class="item">{node.content} </div>
        {node.medias.map(({name}) => (<div class="item"><SnapImg filename={name}></SnapImg></div>))}
    </div>
))}
</div>

</div>
</section>
</Layout>
)
}

export default TravelLog

export const query = graphql`
query
  {
    allTrelloCard(sort: {order: ASC, fields: [list_id, index]}) {
        distinct(field: list_id)
        edges {
          node {
            list_id
            id
            list_index
            list_name
            list_slug
            name
            medias {
              name
            }
            content
          }
          previous {
            list_index
          }
        }
        totalCount
      }
  }
`

